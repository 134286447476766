import React from 'react';
import Label from './Label';
import wrapField from './wrapField';
import { masks } from '../../helperFunctions';

const textField = ({
  input,
  label,
  placeholder,
  elementRef,
  disabled,
  wrapperClasses = '',
  wrapperInnerClasses = '',
  showError = true,
  meta: { touched, error, warning },
}) => (
  <div className={`phoneField-wrapper ${wrapperClasses}`}>
    <Label {...{ input, label }} />
    <div className={`phoneField-wrapper-inner ${wrapperInnerClasses} `}>
      <input
        className="ip-text-block"
        placeholder={placeholder || 'Enter here'}
        type="text"
        value={input.value}
        onBlur={input.onBlur}
        disabled={disabled}
        onChange={(e) => {
          const val = e.target.value;
          const newVal = masks.phone(val);
          input.onChange(newVal);
        }}
        ref={elementRef}
      />
      {showError &&
        touched &&
        ((error && <span className="error">{error}</span>) ||
          (warning && <span>{warning}</span>))}
    </div>
  </div>
);

const wrappedPhoneField = wrapField(textField);
export default textField;
export { wrappedPhoneField };
