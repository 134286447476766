import React from 'react';
import clsx from 'clsx';
import IconButton from '../IconButton';

const ViewButton = ({ onClick, size, type }) => {
  const faClass = 'no-border transparent-bg primary-color-on-hover fa fa-eye';

  if (type === 'font-awesome') {
    return (
      // eslint-disable-next-line jsx-a11y/control-has-associated-label
      <button
        type="button"
        className={clsx(faClass, 'primary-text-color-90')}
        onClick={onClick}
      />
    );
  }

  return (
    <IconButton onClick={onClick} size={size}>
      visibility
    </IconButton>
  );
};
export default ViewButton;
