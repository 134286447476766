import React from 'react';
import clsx from 'clsx';
import styles from './ComponentLoader.module.scss';

const ComponentLoader = ({
  children,
  isLoading,
  iconSize,
  customClass = '',
  customOverlayClass = '',
  customIconClass = '',
  hideContentWhileLoading = false,
  blurContentWhileLoading = false,
  addMinHeight = false,
  dataFor,
  dataTip = false,
  dataClass,
}) => {
  const loaderClass = clsx(styles.topWrapper, customClass);
  return (
    <div
      className={clsx(loaderClass, {
        [styles.blurContentWhileLoading]: blurContentWhileLoading,
        [styles.addMinHeight]: addMinHeight,
      })}
    >
      {hideContentWhileLoading ? (
        <span className={isLoading ? 'opacity-0' : 'opacity-100'}>
          {children}
        </span>
      ) : (
        children
      )}
      {isLoading && (
        <div className={clsx(styles.overlay, customOverlayClass)}>
          <div
            className={clsx(styles.loadingIcon, customIconClass)}
            style={{ width: iconSize, height: iconSize }}
            data-for={dataFor}
            data-class={dataClass}
            data-tip={dataTip}
          />
        </div>
      )}
    </div>
  );
};
export default ComponentLoader;
