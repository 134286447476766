import React from 'react';
import Select, { components } from 'react-select';
import CustomSelectOption from './CustomSelectOption';
import Label from './Label';
import wrapField from './wrapField';

const ValueContainer = ({ children, ...props }) => {
  let [values, input] = children;

  if (Array.isArray(values)) {
    const plural = values.length === 1 ? '' : 's';
    values = `${values.length} item${plural} selected`;
  }

  return (
    <components.ValueContainer {...props}>
      {values}
      {input}
    </components.ValueContainer>
  );
};

const multiSelectArray = ({
  input,
  label,
  options,
  placeholder,
  isOptionDisabled,
  minSelected,
  customClassName,
  styles,
  showRequiredLabel,
  labelClass,
  disabled,
  showClearAll,
  meta: { touched, error, warning },
  numberSelected,
  filterOption,
  textOnly,
  defaultValue,
  getOptionLabel,
  isClearable,
  formatOptionLabel,
  onBeforeOnChange,
  clipLabel = false,
  lucetTheme = false,
  formatText,
  menuIsOpen,
  collapseWhenDisabled = false,
  classSuffix,
  rightAccessory,
}) => {
  const min = minSelected || [];
  let value = [];
  if (defaultValue) {
    const defaultValueArray = defaultValue
      .filter((item) => item.is_required)
      .map((item) => item.value);
    if (Array.isArray(input.value)) {
      const diff = input.value.filter((x) => !defaultValueArray.includes(x));
      value = defaultValueArray.concat(diff);
    } else {
      value = defaultValueArray;
    }
  } else {
    value = Array.isArray(input.value) ? input.value : [];
  }
  const selectedOptions =
    input.value &&
    options.filter((option) => input.value.includes(option.value));

  const MultiValueLabelContainer = ({ children, ...props }) => {
    const val = props.data.isDisabled
      ? props.data.label + formatText
      : props.data.label
      ? props.data.label
      : props.data.name;
    return (
      <components.MultiValueLabel {...props}>
        {val.length > 30 ? val.substr(0, 27) + '...' : val}
      </components.MultiValueLabel>
    );
  };

  const addData = (inputs, newVal) => {
    inputs.onChange(newVal);
  };

  const isMenuOpen = () => {
    let open = menuIsOpen;
    if (collapseWhenDisabled && disabled) {
      open = false;
    }
    return open;
  };

  return (
    <div>
      {textOnly ? (
        <React.Fragment>
          <Label {...{ input, label, labelClass }} />
          <p
            style={{ minHeight: '2.7em' }}
            className={`padding-bottom-sm ${
              input.value.length > 0 ? '' : 'no-info-text'
            }`}
          >
            {input.value.length > 0
              ? selectedOptions.map((option) => (
                  <span className="inl-blk margin-right-md" key={option.value}>
                    {option.label}
                  </span>
                ))
              : 'No information provided'}
          </p>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <div className="flex align-center justify-between">
            <Label {...{ input, label, showRequiredLabel, labelClass }} />
            {showClearAll && !disabled && (
              <span
                onClick={() => {
                  input.onChange([]);
                }}
                className="clear-all-btn"
              >
                Clear all
              </span>
            )}
            {rightAccessory && (
              <span style={{ float: 'right' }}>{rightAccessory}</span>
            )}
          </div>

          <div className="react-select-wrap">
            <Select
              {...input}
              value={value.map((val) =>
                options.find((option) => option.value === val),
              )}
              menuIsOpen={isMenuOpen()}
              defaultValue={defaultValue}
              onChange={(val, meta) => {
                let changedValue;
                if (
                  meta.action === 'select-option' &&
                  (meta.option.is_none_of_the_above ||
                    meta.option.is_all_of_the_above)
                ) {
                  changedValue = [meta.option];
                } else {
                  changedValue = val.filter(
                    (o) => !o.is_none_of_the_above && !o.is_all_of_the_above,
                  );
                }
                const newVal = [
                  ...new Set([...min, ...changedValue.map((e) => e.value)]),
                ];
                if (onBeforeOnChange && meta.action === 'select-option') {
                  onBeforeOnChange(
                    newVal,
                    newVal,
                    input.value,
                    addData.bind(this, input, newVal),
                  );
                } else {
                  input.onChange(newVal);
                }
              }}
              onBlur={() => input.onBlur()}
              options={options}
              placeholder={placeholder || 'Select options'}
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              className={
                customClassName ||
                `react-select${classSuffix ? ` ${classSuffix}` : ''}`
              }
              isMulti
              isDisabled={disabled}
              isOptionDisabled={(option) => option.is_disabled}
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary: lucetTheme ? '#851c55' : '#69ad24',
                },
              })}
              getOptionLabel={getOptionLabel}
              classNamePrefix="custom-react-select"
              formatOptionLabel={formatOptionLabel}
              styles={styles}
              filterOption={filterOption}
              isClearable={isClearable}
              components={{
                Option: CustomSelectOption,
                ...(clipLabel && { MultiValueLabel: MultiValueLabelContainer }),
                ...(numberSelected && { ValueContainer }),
              }}
            />

            {touched &&
              ((error && <span className="error">{error}</span>) ||
                (warning && <span>{warning}</span>))}
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default wrapField(multiSelectArray);
export { multiSelectArray };
