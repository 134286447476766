import React from 'react';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import axios from 'axios';

import { load as loadAccount } from '../../actions/actionCreators';

import selectBox from '../inputs/selectBox';

const formName = 'delete_appointment';
const affects = [
  { value: 'single', label: 'Only This Appointment' },
  { value: 'remainder', label: 'This and All Future Appointments' },
];

const headers = {
  'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'),
};
const saveFormat = 'YYYY-MM-DD[T]HH:mm:ss';

const handleSubmit = (values, dispatch, props) => {
  props.setLoader(true);
  const event = values.original_event;
  const mergedData = { ...values };
  const dateTime = event.recurring_event
    ? moment(event.start_date, saveFormat).format(saveFormat)
    : '';
  const event_id = event.id ? event.id : event.encounter_id;
  deleteAppointment(
    mergedData,
    props.closeModal,
    props.closeModalWithName,
    props.setLoader,
    event_id,
    props.getEvents,
    dateTime,
  );
};

const deleteAppointment = (
  mergedData,
  closeModal,
  closeModalWithName,
  setLoader,
  id,
  getEvents,
) => {
  const data = {
    visit_status: 'cancelled',
    pageName: 'Scheduling Page',
    affects: mergedData.affects,
  };
  axios
    .patch(`/api/v1/visits/${id}`, data, { headers })
    .then((res) => {
      console.log('Created', res.data);
      setLoader(false);
      getEvents();
      closeModal();
      closeModalWithName('EventEditor');
      return res.data;
    })
    .catch((err) => {
      setLoader(false);
      const msg =
        err.response.data && err.response.data.message
          ? err.response.data.message
          : err;
      alert(msg);
    });
};

const validate = (values) => {
  console.log('validated');
  const errors = {};
  if (!values.username) {
    errors.username = 'Required';
  } else if (values.username.length > 15) {
    errors.username = 'Must be 15 characters or less';
  }
  if (!values.email) {
    errors.email = 'Required';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Invalid email address';
  }
  if (!values.age) {
    errors.age = 'Required';
  } else if (Number.isNaN(Number(values.age))) {
    errors.age = 'Must be a number';
  } else if (Number(values.age) < 18) {
    errors.age = 'Sorry, you must be at least 18 years old';
  }
  return errors;
};

// eslint-disable-next-line import/no-mutable-exports
let DeleteAppointment = (props) => {
  const { error, handleSubmit, submitting, closeModal, frequency } = props;
  return (
    <div
      id={`modal-outer-${formName}`}
      className="modal shown custom-modal lucet-brand-modal"
      style={{ display: 'block' }}
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <button
            onClick={closeModal}
            type="button"
            className="close close_birt_modal"
          >
            X
          </button>
          <form id={`form-modal-${formName}`} onSubmit={handleSubmit}>
            <h3 className="text-8 text-center">Cancel Appointment</h3>

            <div className="row-block">
              <Field
                placeholder="Select Option"
                name="affects"
                component={selectBox}
                label="Affects"
                options={affects}
                lucetTheme
              />
            </div>

            {error && <strong>{error}</strong>}

            <div className={`modal-footer ${frequency ? '' : 'txt-center1'}`}>
              <button
                type="button"
                onClick={closeModal}
                className="btn btn-default"
              >
                Back
              </button>
              <button
                id={`btn-primary-${formName}`}
                type="submit"
                disabled={submitting}
                className="btn btn-primary"
              >
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

DeleteAppointment = reduxForm({
  form: 'cancelAppointment', // a unique identifier for this form
  validate, // <--- validation function given to redux-form
  onSubmit: handleSubmit,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(DeleteAppointment);

const valueSelector = formValueSelector('cancelAppointment');

DeleteAppointment = connect(
  (state, ownProps) => {
    const affects = valueSelector(state, 'affects');
    const frequency = valueSelector(state, 'frequency');
    return {
      initialValues: ownProps.initialValues, // pull initial values from account reducer
      fetchedClinicalSummary: state.fetchedClinicalSummary,
      affects,
      frequency,
    };
  },
  { load: loadAccount }, // bind account loading action creator
)(DeleteAppointment);

export default DeleteAppointment;
export { affects as affectsOptions };
