import clsx from 'clsx';
import React from 'react';
import { noop } from '../../../../helperFunctions';

// @todo Have capitalize default as false, move styles to scss file
const PrimaryButton = ({
  children,
  active = true,
  capitalize = true,
  onClick = noop,
  link,
  target,
  rel,
}) => {
  const ButtonType = link ? 'a' : 'button';
  const props = {
    className: clsx('btn', 'btn-primary', !active && 'inactive'),
    style: { textTransform: capitalize ? 'capitalize' : 'none' },
    type: 'button',
    onClick,
  };
  if (link) props.href = link;
  if (target) props.target = target;
  if (rel) props.rel = rel;
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <ButtonType {...props}>{children}</ButtonType>;
};

export default PrimaryButton;
