import React, { useEffect } from 'react';
import Label from './Label';

const checkBox = ({
  input,
  label,
  icon,
  defaultValue,
  disabled,
  hideError,
  meta: { pristine, touched, error, warning },
  labelClass,
}) => {
  useEffect(() => {
    if (pristine && defaultValue != undefined) {
      input.onChange(String(defaultValue) == 'true');
    }
  }, [defaultValue]);
  return (
    <span>
      <span className="checkbox-wrap custom-checkbox">
        <input
          disabled={disabled}
          type="checkbox"
          id={input.name}
          checked={String(input.value) == 'true'}
          {...input}
        />
        <Label
          {...{ input, label, labelClass, icon, htmlFor: input.name }}
          type="checkbox"
        />
      </span>

      {!hideError &&
        touched &&
        ((error && <span className="error">{error}</span>) ||
          (warning && <span>{warning}</span>))}
    </span>
  );
};

export default checkBox;
