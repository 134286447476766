import React from 'react';
import clsx from 'clsx';
import styles from './base_sms.module.scss';
import LanguageSelector, {
  defaultSupportedLanguages,
} from '../../UI/LanguageSelector';

const Sms = ({
  mode,
  children,
  locale = 'en',
  handleChangeLocale,
  metadata,
  bodyClasses = '',
}) => {
  const isLive = mode === 'live'; // mode options case be view, edit or live

  return (
    <div className={clsx(styles.sms, isLive ? 'is-live' : 'is-preview')}>
      {!isLive && metadata?.single_locale && (
        <LanguageSelector
          wraperClasses={clsx(styles.languageSelector, 'selector')}
          currentLocale={locale}
          supportedLanguages={defaultSupportedLanguages}
          onChange={(newLocale) => {
            handleChangeLocale(newLocale);
          }}
        />
      )}
      <div
        className={clsx(styles.smsContent, 'w-100', bodyClasses)}
        aria-live="polite"
      >
        {children}
      </div>
    </div>
  );
};

export default Sms;
