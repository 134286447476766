import React from 'react';
import styles from './IconButton.module.scss';

const IconButton = ({
  onClick,
  size = '1.4em',
  className = 'material-symbols-outlined',
  children,
}) => (
  <button type="button" onClick={onClick} className={styles.button}>
    <span style={{ fontSize: size }} className={className}>
      {children}
    </span>
  </button>
);
export default IconButton;
