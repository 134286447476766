import React, { useCallback } from 'react';

import clsx from 'clsx';
import styles from './message_template_styles.module.scss';
import { confirmOrCancelAppointment } from '../apis';
import CancelReason from '../../appointments/CancelReason';
import { useModal } from '../../../context/modal';
import useLoader from '../../../context/utilities/useLoader';
import { alertApiError } from '../../../helperFunctions';

const ConfirmAppointment = ({ isLive, message, metadata }) => {
  const {
    patient_id: patientID,
    encounter_id: encounterId,
    organization_id: organizationID,
    encounter_accepted: encounterAccepted,
    is_state_changeable: isStateChangeable,
    patient_notification_confirmation_button: confirmationButton,
    is_recurring: isRecurring,
    mss_active: mssActive,
    mss_home_url: mssHomeUrl,
    status_change_reason_enabled: statusChangeReasonEnabled,
    repeat_details: repeatDetails,
    disable_buttons: disableButtons,
    translations,
  } = metadata || {};

  const { setModal, unSetModal } = useModal();
  const { setLoader } = useLoader();

  const handleConfirmOrCancelAppointment = (type) =>
    new Promise((resolve, reject) => {
      if (!isLive) {
        return resolve();
      }
      setLoader(true);
      confirmOrCancelAppointment(encounterId, type)
        .then((r) => {
          const { location } = r.data || {};
          if (location) {
            window.location.href = location;
          }
          resolve();
        })
        .catch((e) => {
          setLoader(true);
          alertApiError(e);
          reject();
        });
      return null;
    });

  const handleCancelAppointmentWithReason = useCallback(() => {
    if (!isLive) {
      return;
    }
    setModal(
      <CancelReason
        customClass={styles.cancelReasonForm}
        classes={{
          defaultBtn: clsx(styles.lucetBtn, styles.defaultBtn),
          primaryBtn: clsx(styles.lucetBtn),
          header: clsx(styles.header),
          customSelectClass: clsx(styles.selectBox),
          customTextAreaClass: clsx(styles.textArea),
        }}
        customProps={{
          modalContentClass: styles.modalContent,
          class: 'with-visible-content',
        }}
        id={encounterId}
        closeModal={unSetModal}
        formName="cancel-reason"
        setLoader={setLoader}
        getEvents={() => {}}
        /* eslint-disable-next-line jsx-a11y/aria-role */
        role="member"
        pathway="secured_message"
        changerId={patientID}
        organizationID={organizationID}
        cancellationProcess={() => handleConfirmOrCancelAppointment('cancel')}
        isRecurring={isRecurring}
      />,
    );
    // eslint-disable-next-line no-restricted-globals
  }, [encounterId]);

  const MessageButtons = () =>
    isStateChangeable ? (
      <div className={clsx(styles.buttonWrapper, 'button-wrapper')}>
        {confirmationButton && (
          // eslint-disable-next-line jsx-a11y/control-has-associated-label,react/button-has-type
          <button
            type="button"
            className={clsx(
              'btn',
              'btn-primary',
              'btn-wrap',
              styles.lucetBtn,
              styles.lucetPrimaryBtn,
            )}
            disabled={disableButtons}
            onClick={() => handleConfirmOrCancelAppointment('confirm')}
          >
            {isRecurring
              ? translations?.confirm_appointments
              : translations?.confirm_appointment}
          </button>
        )}
        <button
          type="button"
          className={clsx(
            'btn',
            'btn-default',
            'btn-wrap',
            styles.lucetBtn,
            styles.defaultBtn,
          )}
          disabled={disableButtons}
          onClick={() => {
            if (statusChangeReasonEnabled) {
              handleCancelAppointmentWithReason();
            } else {
              handleConfirmOrCancelAppointment('cancel');
            }
          }}
        >
          {isRecurring
            ? translations?.cancel_appointments
            : translations?.cancel_appointment}
        </button>
      </div>
    ) : null;

  const InfoText = () => (
    <div className={clsx('other-details', styles.mt1, styles.otherDetails)}>
      {encounterAccepted ? (
        <p>{translations.mss_is_accepted}</p>
      ) : disableButtons ? (
        <p>{translations.already_processed_pls_contact_provider}</p>
      ) : null}
      {mssActive && (
        <p>
          {translations.mss_access_appt_portal},{' '}
          <a
            target="_blank"
            href={mssHomeUrl}
            className={styles.portalLink}
            rel="noreferrer"
          >
            {translations.please_click_here}
          </a>
          .
        </p>
      )}
      {isRecurring && (
        <div className="repeat-det">
          <b>{translations.appointment_repeats}:</b>
          <p>
            <i>{repeatDetails}</i>
          </p>
        </div>
      )}
    </div>
  );

  return (
    <div className={clsx(styles.confirmAppointmentTemplate, 'w-100')}>
      <div dangerouslySetInnerHTML={{ __html: message || '' }} />
      <MessageButtons />
      <InfoText />
    </div>
  );
};

export default ConfirmAppointment;
