import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import AppTooltip from '../../../shared/AppTooltip';
import tStyles from '../../../users/apptootlip.module.scss';

const MaterialIconButton = ({
  onClick,
  children,
  tooltip,
  className = '',
  arrowColor,
  tag = 'button',
  href,
  target = '_self',
}) => {
  const [uniqueId] = useState(() => uuidv4());
  const Tag = tag;

  return (
    <Tag
      data-tip
      data-for={`button-tooltip__${uniqueId}`}
      data-class={`${tStyles.appTooltipReact} ${tStyles.smallSize} word-break-word text-center line-height-1-1`}
      className={`material-symbols-outlined action-button ${className}`}
      type="button"
      onClick={onClick}
      href={href}
      target={target}
    >
      {children}
      <AppTooltip
        id={`button-tooltip__${uniqueId}`}
        text={tooltip}
        arrowColor={arrowColor}
      />
    </Tag>
  );
};

export default MaterialIconButton;
