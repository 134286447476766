import React from 'react';
import clsx from 'clsx';

import styles from './base_email.module.scss';
import LanguageSelector, {
  defaultSupportedLanguages,
} from '../../UI/LanguageSelector';

const Email = ({
  mode,
  locale = 'en',
  handleChangeLocale,
  children,
  bodyClasses = '',
  metadata,
}) => {
  const isLive = mode === 'live'; // mode options case be view, edit or live

  return (
    <div
      className={clsx(styles.baseEmailWrap, isLive ? 'is-live' : 'is-preview')}
      tabIndex="-1"
    >
      <div
        className={clsx('email-content', 'w-100', bodyClasses)}
        aria-live="polite"
      >
        {!isLive && metadata?.single_locale && (
          <LanguageSelector
            wraperClasses={clsx(styles.languageSelector, 'selector')}
            currentLocale={locale}
            supportedLanguages={defaultSupportedLanguages}
            onChange={(newLocale) => {
              handleChangeLocale(newLocale);
            }}
          />
        )}
        {children}
      </div>
    </div>
  );
};

export default Email;
