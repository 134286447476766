import React from 'react';
import clsx from 'clsx';
import styles from './index.module.scss';

const PlusGlyphButton = ({
  children,
  customClasses = {},
  type = 'button',
  onClick,
}) => {
  // eslint-disable-next-line arrow-body-style
  const getLabelClass = (child) => {
    return typeof child === 'string' ? styles.textLabel : '';
  };

  const wrapperClass = clsx(
    'glp-cont no-border transparent-bg',
    styles.wrapper,
    customClasses.wrapper || '',
  );
  const plusClass = clsx(styles.plus, customClasses.plus || '');
  const labelClass = clsx(getLabelClass(children), customClasses.label || '');

  return (
    <button
      className={wrapperClass}
      // eslint-disable-next-line react/button-has-type
      type={type}
      onClick={onClick}
    >
      <span className={`glyphicon glyphicon-plus ${plusClass}`} />
      <span className={labelClass}>{children}</span>
    </button>
  );
};
export default PlusGlyphButton;
