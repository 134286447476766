import React, { useEffect, useState } from 'react';
import { noop } from '../../../../helperFunctions';
import DownloadButton from '../DownloadButton';
import ComponentLoader from '../../ComponentLoader';
import DownloadDone from '../../Indicators/DownloadDone';

const ProcessAndDownload = ({
  onProcessStart = noop,
  startDownload = noop,
}) => {
  const [buttonStatus, setButtonStatus] = useState('idle');

  const resetButtonStatus = () => setButtonStatus('idle');

  const setIsProcessing = () => setButtonStatus('processing');

  const setCompleted = () => setButtonStatus('completed');

  const startProcessing = () => {
    onProcessStart({ setIsProcessing, setCompleted, resetButtonStatus });
  };

  useEffect(() => {
    if (buttonStatus === 'completed') startDownload();
  }, [buttonStatus]);

  return (
    <ComponentLoader
      isLoading={buttonStatus === 'processing'}
      hideContentWhileLoading
      iconSize="30px"
    >
      {buttonStatus === 'completed' ? (
        <DownloadDone />
      ) : (
        <DownloadButton pureButton onClick={startProcessing} />
      )}
    </ComponentLoader>
  );
};

const asyncDownload = (uri) => {
  const link = document.createElement('a');
  link.setAttribute('download', '');
  link.href = uri;
  document.body.appendChild(link);
  link.click();
  link.remove();
};

export default ProcessAndDownload;
export { asyncDownload };
