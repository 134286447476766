import React, { useEffect, useMemo } from 'react';
import WindowedSelect, {
  createFilter,
  components,
} from 'react-windowed-select';
import wrapField from './wrapField';
import Label from './Label';
import { customSelectOptionWithProvidedComponent } from './CustomSelectOption';

const CustomOption = customSelectOptionWithProvidedComponent(components.Option);

const selectBox = ({
  input,
  label,
  options,
  placeholder,
  getOptionLabel,
  disabled,
  searchable,
  isOptionDisabled,
  populateEntry = true,
  formatOptionLabel,
  showRequiredLabel,
  menuIsOpen,
  menuPlacement,
  customStyles,
  customClass,
  customSelectClass,
  meta: { touched, error, warning },
  resetIfOptionNotPresent = true,
  textOnly,
  lucetTheme = false,
  valueKey = 'value',
  labelKey = 'label',
  addPlaceholderOption = false,
  rightAccessory,
  beforeChange,
  caption,
  captionClass,
  labelClass,
}) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (
      resetIfOptionNotPresent &&
      options &&
      options.length === 1 &&
      options[0].value !== input.value &&
      !options[0].isDisabled &&
      populateEntry
    ) {
      input.onChange(options[0].value);
    }
    if (
      resetIfOptionNotPresent &&
      input.value !== '' &&
      options.every((option) => option.value !== input.value)
    ) {
      input.onChange('');
    }
  });
  const customFilter = createFilter({ ignoreAccents: false });
  const selectedOption =
    input.value &&
    options
      .filter((option) => option.value === input.value)
      .map((option) => option.label);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const optionsWithPlaceholder = useMemo(() => {
    if (addPlaceholderOption) {
      const placeholderOption = {};
      placeholderOption[labelKey] = placeholder || 'Select option';
      placeholderOption[valueKey] = '';
      return [placeholderOption].concat(options);
    }
    return options;
  }, [options, input, valueKey, labelKey, placeholder]);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const defaultStyles = addPlaceholderOption
    ? {
        singleValue: (provided, state) => ({
          ...provided,
          color:
            state.data.label === (placeholder || 'Select option')
              ? '#808080'
              : '#333333',
        }),
      }
    : {};

  const customAndDefaultStyles = {
    ...defaultStyles,
    ...customStyles,
  };

  return (
    <div
      className={`component-wrapper ${customClass || ''} ${
        label ? 'has-label' : 'no-label'
      } has-touched-${touched} has-${error ? 'some' : 'no'}-errors`}
    >
      {textOnly ? (
        <React.Fragment>
          <Label {...{ input, label, labelClass }} />
          <p
            className={`padding-bottom-sm ${
              selectedOption ? '' : 'no-info-text'
            }`}
            style={{ minHeight: '2.7em' }}
          >
            {selectedOption || 'No information provided'}
          </p>
        </React.Fragment>
      ) : (
        <React.Fragment>
          {rightAccessory ? (
            <div className="flex align-center justify-between">
              <Label {...{ input, label, showRequiredLabel, labelClass }} />
              <span style={{ float: 'right' }}>{rightAccessory}</span>
            </div>
          ) : (
            <Label {...{ input, label, showRequiredLabel, labelClass }} />
          )}
          <div className="main-field-cont">
            <WindowedSelect
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...input}
              isSearchable={searchable}
              isDisabled={disabled}
              formatOptionLabel={formatOptionLabel}
              menuIsOpen={menuIsOpen}
              value={
                optionsWithPlaceholder.find(
                  (option) => option.value === input.value,
                ) || null
              }
              onChange={async (value) => {
                if (beforeChange) {
                  const feedback = await beforeChange(value.value, input.value);
                  if (feedback === false) return;
                }
                input.onChange(value.value);
              }}
              onBlur={() => input.onBlur()}
              options={optionsWithPlaceholder}
              placeholder={placeholder || 'Select option'}
              hideSelectedOptions={false}
              menuPlacement={menuPlacement}
              className={`react-select ${customSelectClass}`}
              id={
                label
                  ? `${label.toLowerCase().split(' ').join('_')}_select`
                  : ''
              }
              getOptionLabel={getOptionLabel}
              isOptionDisabled={(option) => option.is_disabled}
              styles={customAndDefaultStyles}
              classNamePrefix="custom-react-select"
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary: lucetTheme ? '#851c55' : '#69ad24',
                },
              })}
              filterOption={customFilter}
              components={{ Option: CustomOption }}
            />
            {caption && (
              <span className={`${captionClass || 'error'}`}>
                {caption} <br />
              </span>
            )}

            {touched &&
              ((error && <span className="error">{error}</span>) ||
                (warning && <span>{warning}</span>))}
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default wrapField(selectBox);
