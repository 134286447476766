import React from 'react';
import clsx from 'clsx';

import styles from './base_secure.module.scss';
import LanguageSelector, {
  defaultSupportedLanguages,
} from '../../UI/LanguageSelector';
import LucetLogo from '../../UI/LucetLogo';
import { MaterialIconButton } from '../../UI/Buttons';
import { LanguageTranslator } from '../../../LanguageTranslator';

const Secure = ({
  mode,
  children,
  locale = 'en',
  handleChangeLocale,
  bodyClasses = '',
  metadata,
}) => {
  const isLive = mode === 'live'; // mode options case be view, edit or live

  return (
    <div
      className={clsx(
        styles.baseSecureWrap,
        'lucet-logic-pages',
        isLive ? 'is-live' : 'is-preview',
      )}
      tabIndex="-1"
    >
      <div className={clsx(styles.baseSecureInner, 'base-secure-inner')}>
        <div
          className={clsx(
            styles.secureMessageBodyWrapper,
            'secure-body-wrapper',
          )}
        >
          <div
            className={clsx(
              styles.logoWrapper,
              'bf-login-header',
              'justify-between',
            )}
          >
            <div className={styles.imgWrapper}>
              <LucetLogo
                type="secure_message"
                wrapperClasses="symphony-logo-wrapper"
              />
            </div>
            {metadata?.manage_member_communication_preferences_enabled && (
              <MaterialIconButton
                tooltip={LanguageTranslator.translate(
                  locale,
                  'click_manage_communication_preferences',
                )}
                className={`${styles.notificationBellIcon} color-white-100 text-3xl`}
                arrowColor="#222"
                tag="a"
                href={metadata.manage_member_communication_preferences_url}
                target="_blank"
              >
                notifications
              </MaterialIconButton>
            )}
          </div>
          <div className={clsx(styles.wrapperContent, 'bf-login-detail')}>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <LanguageSelector
              wraperClasses={clsx(styles.languageSelector, 'selector')}
              currentLocale={locale}
              supportedLanguages={defaultSupportedLanguages}
              onChange={(newLocale) => {
                handleChangeLocale(newLocale);
              }}
            />
            <div
              className={clsx(styles.secureBodyWrapper, 'bf-login-detail-body')}
            >
              <div
                className={clsx(styles.content, bodyClasses)}
                aria-live="polite"
              >
                {children}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Secure;
