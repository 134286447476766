import React from 'react';
import clsx from 'clsx';
import styles from './DownloadDone.module.scss';

const DownloadDone = () => (
  <span className={clsx('material-symbols-outlined', styles.downloadDone)}>
    download_done
  </span>
);
export default DownloadDone;
