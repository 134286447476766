import React from 'react';
import IconButton from '../IconButton';

const EditButton = ({ onClick, size }) => (
  <IconButton
    onClick={onClick}
    size={size}
    className="material-symbols-outlined primary-color-on-hover"
  >
    edit
  </IconButton>
);
export default EditButton;
