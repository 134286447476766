import React from 'react';
import clsx from 'clsx';
import { noop } from '../../../../helperFunctions';

const TrashCan = ({ onClick = noop, customClass = '' }) => (
  <button
    type="button"
    className={clsx(
      'glyphicon glyphicon-trash no-border',
      customClass,
      'transparent-bg',
      'primary-color-on-hover',
    )}
    onClick={onClick}
    aria-label="Delete"
  />
);
export default TrashCan;
