import clsx from 'clsx';
import React from 'react';
import { noop } from '../../../../helperFunctions';

const DownloadButton = ({
  href,
  innerText = '',
  buttonClass = 'fa fa-download',
  customClass = '',
  pureButton = false,
  onClick = noop,
}) => {
  const className = clsx(buttonClass, customClass);
  return pureButton ? (
    <button
      className={clsx(className, 'no-border transparent-bg')}
      type="button"
      onClick={onClick}
    >
      {innerText}
    </button>
  ) : (
    <a
      href={href}
      download
      target="_blank"
      rel="noreferrer"
      className={className}
    >
      {innerText}
    </a>
  );
};
export default DownloadButton;
